import Vue, { PropType } from 'vue'
import { GigJobStages } from '@/constants/gig/gigJobStages'
import { mapState } from 'vuex'
import { Badge } from '../Badge'
import Badges from './Badges'
import { JobStatus, STATUS_DRAFT, STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import {
  JobStage,
  STAGE_BLOCKED_BY_FREELANCER,
  STAGE_COMPLETED,
  STAGE_DEADLINE_OVERDUE,
  STAGE_DISPUTED,
  STAGE_IN_PROGRESS,
  STAGE_NEW, STAGE_STARTED
} from '@/constants/job/jobStages'

export function getBadge ({
  stage,
  moderationStage,
  status,
  isDone,
  hasReview,
  hasAcceptedOffer
}: {
  stage: JobStage,
  moderationStage: ModerationStages,
  status: JobStatus,
  isDone: Boolean,
  hasReview: Boolean,
  hasAcceptedOffer?: Boolean
}) {
  if (status === STATUS_DRAFT) return Badges.Draft
  if (moderationStage === ModerationStages.MANUAL) return Badges.Moderation
  if (moderationStage === ModerationStages.FAILED) return Badges.Failed
  if (moderationStage === ModerationStages.PASSED && status === STATUS_PUBLISHED && stage === STAGE_NEW) {
    return Badges.Published
  }
  if (stage === STAGE_STARTED) {
    return hasAcceptedOffer ? Badges.Started : Badges.Published
  }
  if (stage === STAGE_IN_PROGRESS) {
    return isDone ? Badges.IsDone : Badges.InProgress
  }
  if (stage === STAGE_BLOCKED_BY_FREELANCER) return Badges.Dispute
  if (!hasReview) return Badges.Review // Save order!
  switch (stage) {
    case STAGE_COMPLETED: return Badges.Completed
    case STAGE_DEADLINE_OVERDUE: return Badges.Refunded
    case STAGE_DISPUTED: return Badges.Resolved
  }
}

export default Vue.extend<any, any, any, any>({
  props: {
    status: Number as PropType<JobStatus>,
    moderationStage: Number as PropType<ModerationStages>,
    stage: Number as PropType<JobStage>,
    isDone: Boolean,
    hasReview: Boolean,
    hasAcceptedOffer: Boolean,
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    badge (): Badge | undefined {
      return getBadge({
        stage: this.stage,
        moderationStage: this.moderationStage,
        status: this.status,
        isDone: this.isDone,
        hasReview: this.hasReview,
        hasAcceptedOffer: this.hasAcceptedOffer
      })
    },
  },
})
