import Vue from 'vue'
import { mapActions } from 'vuex'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { JOB_DETAILS_ADMIN, JOB_DETAILS_ADMIN_APPLICATIONS, JOB_ADD, JOB_EDIT } from '@/constants/routes'
import { STAGE_NEW, STAGE_STARTED } from '@/constants/job/jobStages'
import { STATUS_PUBLISHED, STATUS_DRAFT, JOB_STATUSES } from '@/constants/job/jobStatuses'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import snackMixin from '@/mixins/snackMixin'
import { JobModerationStagesExplanation } from '@/constants/job/jobModerationStages'
import { validateJobPublish, canPublish } from '@/utils-ts/rules/jobValidation'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import { publishJob, removeJob } from '@/api/jobs/job'
import Button from '@/models-ts/ui/Button'
import CardActionMenu from '../../components/CardActionMenu/CardActionMenu.vue'
import JobTypeBadge from '../../components/JobTypeBadge/JobTypeBadge.vue'
import JobStatusBadge from '@/partials/StatusBadges/JobStatusBadge/JobStatusBadge.vue'
import { getBadge } from '@/partials/StatusBadges/JobStatusBadge/JobStatusBadge'
import Badges from '@/partials/StatusBadges/JobStatusBadge/Badges'
import { formatCardsDescription } from '@/utils-ts/strings'
import zendeskMixin from '@/mixins/zendeskMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [snackMixin, zendeskMixin],
  components: {
    CardActionMenu,
    JobTypeBadge,
    JobStatusBadge,
  },
  props: {
    job: MyCustomerJobListItem,
  },
  data () {
    return {
      JOB_DETAILS_ADMIN,
      JOB_DETAILS_ADMIN_APPLICATIONS,
      JOB_EDIT,
      JOB_ADD,
      publishing: false,
      deleting: false,
      Badges,
    }
  },
  computed: {
    isStarted () {
      return ![STAGE_NEW, STAGE_STARTED].includes(this.job.stage)
    },
    isPublished () {
      return this.job.status === STATUS_PUBLISHED
    },
    description () {
      return formatCardsDescription(this.job.description)
    },
    status () {
      if (this.job.status === STATUS_DRAFT) {
        return JOB_STATUSES[STATUS_DRAFT]
      }
      return JobModerationStagesExplanation[this.job.moderationStage as ModerationStages]
    },
    passedModeration () {
      return this.job.moderationStage === ModerationStages.PASSED
    },
    jobStatus () {
      return getBadge({
        stage: this.job.stage,
        moderationStage: this.job.moderationStage,
        status: this.job.status,
        isDone: false,
        hasReview: false,
      })
    },
    hasEditBtn () {
      return (this.jobStatus === Badges.Moderation || this.jobStatus === Badges.Published) &&
        this.job.stage !== STAGE_STARTED
    },
    hasApplicationsBtn () {
      return this.jobStatus === Badges.Published ||
        (this.jobStatus === Badges.Started && !this.job.hasAcceptedOffer)
    }
  },
  methods: {
    ...mapActions({
      unpublish: 'jobDetails/unpublish',
      openModal: 'ui/openModal',
    }),
    async onClickPublishBtn () {
      if (this.isStarted && this.publishing) {
        return
      }
      if (this.isPublished) {
        await this.onUnpublishJob()
      } else {
        await this.onPublishJob()
      }
    },
    async onPublishJob () {
      try {
        this.publishing = true
        const validationError = validateJobPublish(this.job)
        if (!validationError) {
          let status, moderationStage
          await notifiableRequest({
            request: async () => {
              const job = await publishJob(this.job.id)
              status = job.status
              moderationStage = job.moderation_stage
            },
            title: 'Publish job',
            successText: this.job.moderationStage === ModerationStages.MANUAL
              ? 'Your Job was successfully submitted for moderation. We will notify you of the outcome shortly.'
              : `You have published a Job <b>${this.job.name}</b>`,
            failureText: 'Error publish a Job'
          })
          googleAnalyticsV2.send({
            event: 'job-published',
            job_id: this.job.id,
          })
          this.$emit('changeStatus', {
            status: STATUS_PUBLISHED,
            job: new MyCustomerJobListItem({ ...this.job, status, moderationStage }),
          })
        } else {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: validationError,
          })
        }
      } finally {
        this.publishing = false
      }
    },
    onUnpublishJob () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/UnpublishJobModal/UnpublishJobModal.vue'),
          title: 'Unpublish Job',
          props: {
            job: this.job,
            onSuccess: () => {
              googleAnalyticsV2.send({
                event: 'job-drafted',
                job_id: this.job.id,
              })
              this.$emit('changeStatus', {
                status: STATUS_DRAFT,
                job: new MyCustomerJobListItem({ ...this.job, status: STATUS_DRAFT }),
              })
            },
            onFail: () => {},
          }
        }
      })
    },
    onClickDeleteBtn () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Remove Job',
          text: `Are you sure you want to remove Job: <b>${this.job.name}</b>`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
            }),
            new Button({
              text: 'Proceed',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  this.deleting = true
                  await notifiableRequest({
                    request: () => removeJob(this.job.id),
                    title: 'Remove Job',
                    successText: `Job <b>${this.job.name}</b> has been deleted.`,
                    failureText: 'Error deleting Job. Please try again.'
                  })
                  this.$emit('delete', this.job)
                } finally {
                  this.deleting = false
                }
              },
            }),
          ]
        }
      })
    },
    onClickSupport () {
      if (!this.isZendeskLoading) {
        this.openZendesk('My account')
      }
    },
  },
})
