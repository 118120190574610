import Vue from 'vue'
import { mapMutations } from 'vuex'
import { JOB_ADD, SERVICES } from '@/constants/routes'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import MyGigApplication from '@/models-ts/gigs/MyGigApplication'
import MyVacancyListItem from '@/models-ts/vacancies/MyVacancyListItem'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import { JobStatus, STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import { STAGE_NEW, STAGE_STARTED } from '@/constants/job/jobStages'
import JobPostedCardCustomer from '../../cards/job/JobPostedCardCustomer/JobPostedCardCustomer.vue'
import JobActiveCardCustomer from '../../cards/job/JobActiveCardCustomer/JobActiveCardCustomer.vue'
import MyVacancyCard from '../../cards/vacancy/MyVacancyCard/MyVacancyCard.vue'
import GigJobCard from '../../cards/gig/GigJobCard/GigJobCard.vue'
import OfferCard from '../../cards/gig/OfferCard/OfferCard.vue'
import { Statuses } from '@/constants/vacancies/statuses'

type ActiveJob = MyCustomerJobListItem | MyGigApplication | MyVacancyListItem | GigOfferListItem

export default Vue.extend<any, any, any, any>({
  components: {
    GigJobCard,
    JobActiveCardCustomer,
    MyVacancyCard,
    OfferCard,
    JobPostedCardCustomer,
  },
  props: {
    jobs: Array,
    isLoading: Boolean,
    isLoaded: Boolean,
  },
  data () {
    return {
      JOB_ADD,
      SERVICES,
    }
  },
  computed: {
    renderingJobs () {
      return this.jobs.map(this.getComponent)
    },
  },
  methods: {
    ...mapMutations({
      setCustomerJobPublish: 'dashboard/setCustomerJobPublish',
      setCustomerJobUnpublish: 'dashboard/setCustomerJobUnpublish',
      setCustomerVacancyPublish: 'dashboard/setCustomerVacancyPublish',
      setCustomerVacancyUnpublish: 'dashboard/setCustomerVacancyUnpublish',
      setCustomerJobDelete: 'dashboard/setCustomerJobDelete',
      setCustomerVacancyDelete: 'dashboard/setCustomerVacancyDelete',
      setCustomerVacancyArchive: 'dashboard/setCustomerVacancyArchive',
    }),
    getComponent (job: ActiveJob) {
      if (job instanceof MyCustomerJobListItem) {
        return {
          component: STAGE_NEW === job.stage || (STAGE_STARTED === job.stage && !job.hasAcceptedOffer)
            ? JobPostedCardCustomer
            : JobActiveCardCustomer,
          id: job.id,
          props: {
            job,
          },
          handlers: {
            changeStatus: ({ status, job }: { status: JobStatus, job: MyCustomerJobListItem }) => {
              if (status === STATUS_PUBLISHED) {
                this.setCustomerJobPublish(job)
              } else {
                this.setCustomerJobUnpublish(job)
              }
              this.$emit('changeTab', status === STATUS_PUBLISHED ? 'active' : 'draft')
            },
            delete: (job: MyCustomerJobListItem) => {
              this.setCustomerJobDelete({ job, isActive: true })
            },
          }
        }
      } else if (job instanceof MyVacancyListItem) {
        return {
          component: MyVacancyCard,
          id: job.id,
          props: {
            vacancy: job,
          },
          handlers: {
            changeStatus: ({ status, vacancy }: { status: Statuses, vacancy: MyVacancyListItem }) => {
              if (status === Statuses.PUBLISHED) {
                this.setCustomerVacancyPublish(vacancy)
              } else {
                this.setCustomerVacancyUnpublish(vacancy)
              }
              this.$emit('changeTab', status === STATUS_PUBLISHED ? 'active' : 'draft')
            },
            delete: (job: MyVacancyListItem) => {
              this.setCustomerVacancyDelete({ job, type: 'active' })
            },
            archive: (job: MyVacancyListItem) => {
              this.setCustomerVacancyArchive({ job, type: 'active' })
              this.$emit('changeTab', 'archive')
            },
          }
        }
      } else if (job instanceof GigOfferListItem) {
        return {
          component: GigJobCard,
          // @ts-ignore
          id: job.id,
          props: {
            gig: job,
            isFreelancer: false,
          }
        }
      } else {
        return {
          component: OfferCard,
          id: job.id,
          props: {
            application: job,
          }
        }
      }
    },
  }
})
