import { mapActions, mapGetters, mapState } from 'vuex'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import { JOB_DETAILS, JOB_EDIT, JOB_CHAT } from '@/constants/routes'
import { DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN } from '@/constants/utils/date'
import {
  STAGE_NEW,
  STAGE_IN_PROGRESS,
  STAGE_STARTED,
  STAGE_BLOCKED_BY_FREELANCER, STAGE_COMPLETED, STAGE_DISPUTED, STAGE_DEADLINE_OVERDUE,
} from '@/constants/job/jobStages'
import { Blockchain, BLOCKCHAIN_OPTIONS, BlockchainNames } from '@/constants/blockchain'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import JobApplication from '@/models-ts/job/JobApplication'
import { convertToUsd, getCurrency } from '@/utils/currency'
import { formatCurrency } from '@/utils/moneyFormat'
import { getTxLink } from '@/utils/etherscan'
import { formatDate, addDays } from '@/utils/date'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import JobStatusBadge from '@/partials/StatusBadges/JobStatusBadge/JobStatusBadge.vue'
import JobApplicationStatusBadge from '@/partials/StatusBadges/JobApplicationStatusBadge/JobApplicationStatusBadge.vue'
import Vue, { PropType } from 'vue'
import { RootState } from '@/store'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'
import { CURRENCY_FIELD_BACKEND_ID } from '@/constants/currency'
import { JobOfferStages } from '@/constants/job/jobOfferStages'

export default Vue.extend<any, any, any, any>({
  components: {
    UserInfo,
    JobStatusBadge,
    JobApplicationStatusBadge,
  },
  props: {
    application: JobApplication,
  },
  data () {
    return {
      JOB_DETAILS,
      BlockchainNames,
      DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN,
      JOB_EDIT,
      publishing: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      userId: (state: RootState) => state.user?.id,
      rates: (state: RootState) => state.rates.rates.value,
      chatInitialized: (state: RootState) => state.chatNew?.initialized,
      chatConnected: (state: RootState) => state.chatNew?.connected,
    }),
    ...mapGetters({
      getUnreadCount: 'chatNew/getUnreadCount',
    }),
    chatRoomId () {
      return ChatRoom.generateRoomId({
        type: RoomTypes.JOB,
        applicationId: this.application.id,
        freelancerId: this.application.freelancer.id,
        customerId: this.application.customer.id,
      })
    },
    chatLink () {
      return {
        name: JOB_CHAT,
        params: { id: this.application.id, slug: this.application.job.slug },
        query: { room: this.chatRoomId }
      }
    },
    unreadMsgs () {
      return this.getUnreadCount(this.chatRoomId)
    },
    hasChat () {
      return this.application.id && this.chatInitialized
    },
    hasReview () {
      return this.isCompleted && this.application.hasFreelancerLeftReview
    },
    isStarted () {
      return ![STAGE_NEW, STAGE_STARTED].includes(this.application.stage)
    },
    isInProgress () {
      return this.application.status === JobApplicationStatuses.NEW &&
        [
          STAGE_IN_PROGRESS,
          STAGE_BLOCKED_BY_FREELANCER
        ].includes(this.application.job.stage)
    },
    isOfferDeclined () {
      return [
        JobOfferStages.DECLINED_BY_CUSTOMER,
        JobOfferStages.DECLINED_BY_FREELANCER
      ].includes(this.application.offer.stage)
    },
    isApplication () {
      return [STAGE_NEW, STAGE_STARTED].includes(this.application.job.stage) ||
        this.isOfferDeclined
    },
    isArchived () {
      return this.application.status === JobApplicationStatuses.ARCHIVED &&
        [STAGE_NEW,
          STAGE_STARTED,
          STAGE_IN_PROGRESS,
          STAGE_BLOCKED_BY_FREELANCER
        ].includes(this.application.job.stage)
    },
    amISelectedFreelancer () {
      return this.application.selectedFreelancerId === this.userId
    },
    isCompleted () {
      return this.amISelectedFreelancer &&
        [
          STAGE_COMPLETED,
          STAGE_DISPUTED,
          STAGE_DEADLINE_OVERDUE
        ].includes(this.application.job.stage) &&
        !this.isOfferDeclined
    },
    isMember () {
      return this.isStarted && !this.isArchived
    },
    primaryBudget () {
      if (!this.isMember) {
        return formatCurrency(this.application.budget, { currency: 'USDT' })
      }
      const budget = this.application.budget
      const currency = getCurrency({
        blockchain: this.application.job.blockchain,
        value: this.application.job.currency,
        field: CURRENCY_FIELD_BACKEND_ID
      })
      return this.isStarted ? `${budget} ${currency?.name}` : `$${budget}`
    },
    secondaryBudget () {
      if (this.isMember) {
        return `$${convertToUsd({
          value: this.application.job.escrowBalance,
          currency: this.application.job.currency,
          rates: this.rates
        })}`
      }
    },
    transactionStartLink () {
      return this.application.job.txIdCreated && getTxLink({
        blockchain: this.application.job.blockchain,
        tx: this.application.job.txIdCreated,
        chainId: null
      })
    },
    transactionEndLink () {
      return this.application.job.txIdCompleted && getTxLink({
        blockchain: this.application.job.blockchain,
        tx: this.application.job.txIdCompleted,
        chainId: null
      })
    },
    completedDate () {
      return this.application.job.completedAt && formatDate(this.application.job.completedAt, DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN)
    },
    deadline () {
      return this.application.inProgressAt &&
        formatDate(addDays(this.application.inProgressAt, this.application.estimate), DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN)
    },
    appDate () {
      return this.isApplication &&
        formatDate(this.application.createdAt, DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN)
    },
    goToLink () {
      return { name: JOB_DETAILS, params: { id: this.application.id, slug: this.application.slug } }
    },
    usd () {
      return convertToUsd({ value: this.application.budget, currency: this.application.currency, rates: this.rates })
    },
    deadlineDaysFormatted () {
      return this.application.job.estimate
        ? `${this.application.job.estimate} ${this.application.job.estimate === 1 ? 'day' : 'days'}`
        : 'TBD'
    },
  },
  methods: {
    ...mapActions({
      openRoom: 'chatNew/openRoom',
    }),
    onChatClick () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatLink)
    },
    getBlockchainLabel (blockchain: Blockchain) {
      return BLOCKCHAIN_OPTIONS.find(bl => bl.value === blockchain)?.name || ''
    },
  },
})
